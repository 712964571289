import React, { useEffect } from 'react'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import { withPrismicUnpublishedPreview, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import { useTransitionClasses } from "../hooks/useTransitionClasses"
import { useLocalState } from '../components/Layout'
import Page from '../templates/Page'
import AuthorPage from '../templates/AuthorPage'
import EssayPage from '../templates/EssayPage'
import InterviewPage from '../templates/InterviewPage'
import IssuePage from '../templates/IssuePage'
import PhotoStoryPage from '../templates/PhotoStoryPage'
import PoemPage from '../templates/PoemPage'

const NotFoundPage = ({ transitionStatus }) => {
  const { isNotFound, setIsNotFound } = useLocalState(),
    transitionClasses = useTransitionClasses({ transitionStatus })

  useEffect(() => {
    setIsNotFound(true)

    return () => {
      setIsNotFound(false)
    }
  }, [])

	return (
		<div className={transitionClasses}>
      <div className="flex flex-col" style={{
        minHeight: `max(100vh, calc(var(--issue-index-height) + var(--footer-height) + 192px))`,
      }}>
        <Seo title="404 Not Found" />
        <div className="small-container mt-48 mb-auto">
          <h1 className="text-center max-w-sm mx-auto">
            404<br />
            Not Found
          </h1>
          <article className="mt-12 mb-48">
            <div className="small-container px-6">
              <p>
                Nothing exists at the requested URL.{` `}
                <TransitionLink
                  to="/"
                  exit={pageTransitionOut}
                  entry={pageTransitionIn}
                  >
                  Go home?
                </TransitionLink>
              </p>
            </div>
          </article>
        </div>
        <Footer />
      </div>
		</div>
	)
}

export default withPrismicUnpublishedPreview(NotFoundPage, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
  linkResolver,
  htmlSerializer,
  componentResolver: componentResolverFromMap({
		page: Page,
		author: AuthorPage,
		essay: EssayPage,
		interview: InterviewPage,
		issue: IssuePage,
		photo_story: PhotoStoryPage,
    poem: PoemPage,
	}),
}])
