import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Logo } from '../components/Logo'
import TableOfContents from '../components/TableOfContents'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import { useLocalState } from '../components/Layout'
import { useTransitionClasses } from "../hooks/useTransitionClasses"
import ArticleFooter from '../components/ArticleFooter'
import ArticleHeader from '../components/ArticleHeader'
import { RichText } from 'prismic-reactjs'

const IssuePage = ({ data, transitionStatus }) => {
  const context = useLocalState(),
    issue = data.prismicIssue,
    issueUrl = linkResolver(issue),
    issueColor = issue?.data?.color,
    issueNumber = parseInt(issue?.data?.issue_number?.text ?? `0`, 10),
    isPreview = issueNumber > context.issueNumber,
    transitionClasses = useTransitionClasses({ transitionStatus })

    // If an issue page is loaded for an issue with a number greater than the highest published,
    // We can reasonably assume this is a preview and update a number of variables in Layout.
    // This is a manual approach to previewing some elements that one might think would be handled
    // by gatsby-plugin-prismic-previews, but apparently are not.

    useEffect(() => {
      if(!isPreview) return
      context.setPreviewIssue(issue)
      context.setColor(issueColor)
      context.setIssueNumber(issueNumber)
    }, [isPreview])

	return (
		<div className={transitionClasses}>
			<Seo
        description={issue?.data?.editors_foreword?.text}
        image={issue?.data?.featured_image}
				title={`Issue No ${issue?.data?.issue_number.text}, ${issue?.data?.issue_title.text}`}
			/>
      <article>
        <ArticleHeader
          author={issue?.data?.editors_letter_author}
          featuredImage={issue?.data?.featured_image}
          issueNumber={issue?.data?.issue_number.text}
          issueSlug={issueUrl}
          issueTitle={issue?.data?.issue_title?.text}
          title={issue?.data?.editors_title?.text === ``
            ? { text: `Issue No. ${issue.data.issue_number.text} ${issue.data.issue_title.text}` }
            : issue?.data?.editors_title
          }
          type="letter"
        />
        <div className="small-container">
          {issue?.data?.editors_foreword?.html && (
            <h5>
              <RichText
                htmlSerializer={htmlSerializer}
                render={issue.data.editors_foreword.raw}
              />
            </h5>
          )}
          {issue.data.playlist.url && (
            <h5>
              <a href={issue.data.playlist.url}>Listen to this issue’s playlist on Spotify.</a>
            </h5>
          )}
          <div className="mt-8">
            <RichText
              htmlSerializer={htmlSerializer}
              render={issue.data.editors_body.raw}
            />
          </div> 
        </div>
        <ArticleFooter author={issue?.data?.editors_letter_author} />
			</article>
			<div className="large-container">
        <TransitionLink className="block w-min my-48 mx-auto" to="/" entry={pageTransitionIn} exit={pageTransitionOut}>
          <Logo className="w-12 h-auto mx-auto text-blue fill-current" />
        </TransitionLink>
				<TableOfContents articles={issue.data.articles} tight />
			</div>
      <Footer />
		</div>
	)
}

export default withPrismicPreview(IssuePage, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
  linkResolver,
  htmlSerializer,
}])

export const IssueQuery = graphql`
	query IssueQuery($id: String!) {
		prismicIssue(id: { eq: $id }) {
      _previewable
      id
      type
			uid
			data {
				issue_title {
					text
				}
				issue_number {
					text
				}
        playlist {
          url
        }
        editors_title {
          text
        }
				editors_foreword {
					html
          text
          raw
				}
				editors_letter_author {
					document {
						... on PrismicAuthor {
							id
							uid
              type
							data {
								author_bio {
									text
									html
                  raw
								}
								author_name {
									text
								}
							}
						}
					}
				}
				featured_image {
          alt
          gatsbyImageData
				}
				editors_body {
					html
          raw
				}
				articles {
					article {
						uid
            type
						document {
							... on PrismicEssay {
								id
								uid
								data {
									excerpt {
										text
									}
									title {
										text
									}
									featured_image {
                    alt
                    gatsbyImageData
									}
								}
								type
							}
							... on PrismicInterview {
								id
								uid
								type
								data {
									excerpt {
										text
									}
									title {
										text
									}
									featured_image {
                    alt
                    gatsbyImageData
									}
								}
							}
							... on PrismicPhotoStory {
								id
								uid
								type
								data {
									excerpt {
										text
									}
									title {
										text
									}
									featured_image {
                    alt
                    gatsbyImageData
									}
								}
							}
              ... on PrismicPoem {
								id
								uid
								data {
									excerpt {
										text
									}
									title {
										text
									}
									featured_image {
                    alt
                    gatsbyImageData
									}
								}
								type
							}
						}
					}
				}
			}
		}
	}
`
