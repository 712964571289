import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import SliceZone from '../components/SliceZone'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import { useTransitionClasses } from "../hooks/useTransitionClasses"

const Page = ({ data, transitionStatus }) => {
	const {
		prismicPage: {
			data: { body },
		},
	} = data,
    transitionClasses = useTransitionClasses({ transitionStatus }),
    firstTextContent = body.map(slice => slice?.primary?.rich_text?.text ?? undefined).filter(text => text)[0],
    firstImageContent = body.map(slice => slice?.primary?.the_image ?? undefined).filter(image => image)[0]

	return (
		<div className={transitionClasses}>
      <div className="flex flex-col" style={{
        minHeight: `max(100vh, calc(var(--issue-index-height) + var(--footer-height) + 192px))`
      }}>
        <Seo
          description={firstTextContent}
          title={data.prismicPage.data.title.text}
          image={firstImageContent}
        />
        {/* <IssueIndex linkToIssuePage={true} /> */}
        <div className="small-container pt-24 lg:pt-48">
          <h1 className="text-center w-sm mx-auto">
            {data.prismicPage.data.title.text}
          </h1>
        </div>
        <article className="mt-12 mb-auto">
          <SliceZone allSlices={body} />
        </article>
        <Footer />
      </div>
		</div>
	)
}

export default withPrismicPreview(Page, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
  linkResolver,
  htmlSerializer,
}])

export const pageQuery = graphql`
	query PageQuery($id: String!) {
		prismicPage(id: { eq: $id }) {
      _previewable
			id
      type
			uid
			data {
				title {
					text
				}
				body {
					... on PrismicPageDataBodyImage {
						id
						slice_type
						primary {
							caption {
								text
							}
							image_size
							the_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
					... on PrismicPageDataBodyContent {
						id
						slice_type
						primary {
							rich_text {
								html
                text
                raw
							}
						}
					}
          ... on PrismicPageDataBodyHtml {
              id
              slice_type
              primary {
                html
              }
            }
				}
			}
		}
	}
`
