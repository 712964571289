import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import ArticleHeader from '../components/ArticleHeader'
import ArticleFooter from '../components/ArticleFooter'
import SliceZone from '../components/SliceZone'
import OtherArticles from '../components/OtherArticles'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import { useTransitionClasses } from "../hooks/useTransitionClasses"

const PoemPage = ({ data, pageContext, transitionStatus }) => {
	const { author, excerpt, title, featured_image, body } = data.prismicPoem.data,
		{ issue_number, issue_slug, issue_title, prev, next } = pageContext,
    seoTitle = author?.document?.data?.author_name?.text
      ? `${title.text}, a poem by ${author.document.data.author_name.text}`
      : title.text,
		firstExtraLargeImageIndex = body.findIndex(
			(slice) =>
				slice.slice_type === `image` &&
				slice.primary.image_size === `Extra Large`
		),
    transitionClasses = useTransitionClasses({ transitionStatus })

	let slicesBeforeFirstExtraLargeImage = body,
		slicesAfterFirstExtraLargeImage = undefined

	if (firstExtraLargeImageIndex > -1) {
		slicesBeforeFirstExtraLargeImage = body.slice(
			0,
			firstExtraLargeImageIndex
		)
		slicesAfterFirstExtraLargeImage = body.slice(firstExtraLargeImageIndex)
	}

	return (
		<div className={transitionClasses}>
			<Seo
        description={excerpt?.text}
        image={featured_image}
        title={seoTitle}
      />
			<article>
				<div>
					<ArticleHeader
						author={author}
						featuredImage={featured_image}
            issueNumber={issue_number}
						issueSlug={issue_slug}
						issueTitle={issue_title}
						title={title}
						type="poem"
					/>
					<SliceZone allSlices={slicesBeforeFirstExtraLargeImage} />
				</div>
				{slicesAfterFirstExtraLargeImage && (
					<SliceZone allSlices={slicesAfterFirstExtraLargeImage} />
				)}
        <ArticleFooter author={author} />
			</article>
			<OtherArticles prev={prev} next={next} issueSlug={issue_slug} />
      <Footer />
		</div>
	)
}

export default withPrismicPreview(PoemPage, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
  linkResolver,
  htmlSerializer,
}])

export const PoemQuery = graphql`
	query PoemQuery($id: String!) {
		prismicPoem(id: { eq: $id }) {
      _previewable
			id
      type
			uid
			data {
				author {
					document {
						... on PrismicAuthor {
							id
							uid
              type
							data {
								author_bio {
									text
									html
                  raw
								}
								author_name {
									text
								}
							}
						}
					}
				}
				title {
					text
				}
        excerpt {
          text
        }
				featured_image {
          alt
          gatsbyImageData
				}
				body {
					... on PrismicPoemDataBodyContent {
						id
						slice_type
						primary {
							rich_text {
								html
                raw
							}
						}
					}
					... on PrismicPoemDataBodyImage {
						id
						slice_type
						primary {
							image_size
							caption {
								text
							}
							the_image {
								alt
                gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
				}
			}
		}
	}
`
